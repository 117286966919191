import React, { Component } from "react";
import { match, Redirect, RouteComponentProps, withRouter } from "react-router";
import {
  BorderBox,
  InputContainer,
  NameInput,
  Paragraph,
  ResultContainer,
  StartButton,
  SubTitle,
  Title
} from "../components";
import PoetryGenerator from "../generators/PoetryGenerator";
import { DEFAULT_NAME, ResultModel } from "../models";
import Canvas from "./Canvas";

type Props = RouteComponentProps & {
  match: match<{ token: string }>;
};
type State = {
  name: string;
  result: ResultModel | null;
};
class Result extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      name: "",
      result: PoetryGenerator.from(
        props.match.params.token,
        props.location.search
      )
    };
  }
  generate = () => {
    const name = this.state.name || DEFAULT_NAME;
    const result = PoetryGenerator.generate(name);
    this.props.history.push("/1/", {
      name,
      result
    });
  };
  render() {
    if (!this.state.result) {
      return <Redirect to="/1/" />;
    }
    return (
      <div style={{ width: "100%" }}>
        <Title>
          あなたの
          <br />
          人生を変えるポエム
        </Title>
        <InputContainer>
          <BorderBox>
            <Paragraph>診断したい名前を入れてください。</Paragraph>
            <NameInput
              type="text"
              name="name"
              value={this.state.name}
              placeholder={DEFAULT_NAME}
              onChange={e => this.setState({ name: e.target.value })}
            />
            <StartButton onClick={this.generate}>診断する</StartButton>
          </BorderBox>
        </InputContainer>
        <ResultContainer>
          {this.state.result.name && (
            <SubTitle>
              こう思う日が
              <br />
              {this.state.result.name}さんに訪れるでしょう
            </SubTitle>
          )}
          <Canvas result={this.state.result} shouldUpload={false} />
        </ResultContainer>
      </div>
    );
  }
}

export default withRouter(Result);
