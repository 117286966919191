import React, { Component } from "react";
import ReactGA from "react-ga";
import { RouteComponentProps, withRouter } from "react-router";
import {
  BorderBox,
  InputContainer,
  LineShareButton,
  NameInput,
  Paragraph,
  ResultContainer,
  StartButton,
  SubTitle,
  Title,
  TwitterShareButton
} from "../components";
import CopyResultSection from "../components/CopyResultSection";
import PoetryGenerator from "../generators/PoetryGenerator";
import { DEFAULT_NAME, ResultModel, shareText } from "../models";
import Canvas from "./Canvas";

type Props = RouteComponentProps<{ name: string }> & {};
type State = {
  name: string;
  result?: ResultModel;
};

class Generator extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      name: ""
    };
  }
  componentDidMount() {
    this.update(this.props);
  }
  componentWillReceiveProps(props: Props) {
    this.update(props);
  }
  update = (props: Props) => {
    window.scrollTo(0, 0);
    if (props.location.state) {
      this.setState({
        ...props.location.state
      });
    } else {
      this.setState({
        name: "",
        result: undefined
      });
    }
  };
  generate = () => {
    const result = PoetryGenerator.generate(
      this.state.name ||
        (this.props.location.state && this.props.location.state.name) ||
        DEFAULT_NAME
    );
    this.props.history.push("/1/", {
      name: (result && result.name) || DEFAULT_NAME,
      result
    });
  };
  copyShareText = (textArea: HTMLTextAreaElement) => {
    ReactGA.event({ category: "Poetry", action: "Share", label: "Copy" });
    var range = document.createRange();
    range.selectNode(textArea);
    window.getSelection()!.addRange(range);
    document.execCommand("copy");
    window.getSelection()!.removeAllRanges();
  };
  shareImage = (url: string) => {
    console.log(url);
    window.open(url, "_blank");
  };
  render() {
    return (
      <div style={{ width: "100%" }}>
        <Title>
          {(this.state.result && this.state.result.name) || "あなた"} の<br />
          人生を変えるポエム
        </Title>
        {this.state.result && (
          <ResultSection
            result={this.state.result}
            onPressCopy={this.copyShareText}
            onPressShare={this.shareImage}
          />
        )}
        <InputContainer>
          <BorderBox>
            <Paragraph>診断したい名前を入れてください</Paragraph>
            <NameInput
              type="text"
              name="name"
              value={this.state.name}
              placeholder={DEFAULT_NAME}
              onChange={e => this.setState({ name: e.target.value })}
            />
            <StartButton onClick={this.generate}>診断する</StartButton>
          </BorderBox>
        </InputContainer>
      </div>
    );
  }
}

const ResultSection = (props: {
  result: ResultModel;
  onPressCopy: (textArea: HTMLTextAreaElement) => void;
  onPressShare: (url: string) => void;
}) => (
  <ResultContainer>
    <SubTitle>
      こう思う日が
      <br />
      {props.result.name}さんに訪れるでしょう
    </SubTitle>
    <Canvas result={props.result} shouldUpload={true} />
    <div>
      <Paragraph>結果をつぶやこう</Paragraph>
      <TwitterShare result={props.result} onPress={props.onPressShare} />
      <LineShare result={props.result} onPress={props.onPressShare} />
    </div>
    <CopyResultSection result={props.result} onPressCopy={props.onPressCopy} />
  </ResultContainer>
);

const TwitterShare = (props: {
  result: ResultModel;
  onPress: (url: string) => void;
}) => (
  <TwitterShareButton
    onClick={() => {
      ReactGA.event({ category: "Poetry", action: "Share", label: "Twitter" });
      const text = shareText(props.result, "twitter");
      props.onPress(
        `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}`
      );
    }}
  >
    <img src="/images/twitter.png" width="30" height="30" />
    ツイートする
  </TwitterShareButton>
);
const LineShare = (props: {
  result: ResultModel;
  onPress: (url: string) => void;
}) => (
  <LineShareButton
    onClick={() => {
      ReactGA.event({ category: "Poetry", action: "Share", label: "LINE" });
      const text = shareText(props.result, "line");
      props.onPress(`https://line.me/R/msg/text/?${encodeURIComponent(text)}`);
    }}
  >
    <img
      src="/images/line.png"
      width="30"
      height="30"
      style={{ marginRight: 5 }}
    />
    LINEに送る
  </LineShareButton>
);

export default withRouter(Generator);
