import firebase from "firebase/app";
import "firebase/storage";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { ResultCanvas } from "../components";
import { ResultModel } from "../models";

type Props = {
  result: ResultModel;
  shouldUpload: boolean;
};
type State = {
  loading: boolean;
  isUploaded: boolean;
  isFontLoaded: boolean;
  windowWidth: number;
};
export default class Canvas extends Component<Props, State> {
  canvas: HTMLCanvasElement | null = null;
  image = new Image();
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      isUploaded: false,
      windowWidth: 0,
      isFontLoaded: false
    };
    WebFont.load({
      custom: {
        families: ["beautiful"]
      },
      active: () => {
        this.setState({ isFontLoaded: true });
        this.draw(this.props.result);
      }
    });
  }
  componentWillReceiveProps(props: Props) {
    const isNewRequest = this.props.result.token != props.result.token;
    if (isNewRequest) {
      this.setState({ loading: isNewRequest, isUploaded: !isNewRequest });
      this.clearCanvas(this.canvas!);
      this.draw(props.result);
    }
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }
  updateWindowDimensions = () => {
    this.setState({ windowWidth: Math.min(480, window.innerWidth) - 40 });
    this.draw(this.props.result);
  };
  draw = (result: ResultModel) => {
    this.image.src = "";
    this.image.onload = () => {
      if (!this.canvas || !this.state.isFontLoaded) {
        return;
      }
      this.drawToCanvas(this.canvas, result);
      this.props.shouldUpload && this.upload(result);
      this.setState({ loading: false });
    };
    this.image.onerror = e => console.error(e);
    this.image.src = result.imageUrl;
  };
  clearCanvas = (canvas: HTMLCanvasElement) => {
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return;
    }
    ctx.save();
    ctx.fillStyle = "rgb(0,0,0)";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.restore();
  };
  drawToCanvas = (canvas: HTMLCanvasElement, result: ResultModel) => {
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return;
    }
    ctx.save();
    ctx.drawImage(
      this.image,
      0,
      (canvas.height - canvas.width) / 2,
      canvas.width,
      canvas.width
    );
    ctx.fillStyle = "rgba(0,0,0,0.3)";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    const fontSize = Math.round(canvas.width / 16.0);
    ctx.fillStyle = "rgba(255,255,255,1)";
    ctx.shadowColor = "rgba(0,0,0,1)";
    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 0;
    ctx.shadowBlur = canvas.width / 20;
    ctx.font = `normal ${fontSize}px 'beautiful', serif`;
    ctx.textAlign = "center";
    result.text.split("\n").forEach((t, index) => {
      const y = canvas.height / 2 + fontSize * 1.5 * (index - 0.8);
      ctx.fillText(t, canvas.width / 2.0, y, canvas.width);
    });
    ctx.restore();
  };
  dummyCanvas: HTMLCanvasElement | null = null;
  upload = (result: ResultModel) => {
    if (!this.state.isUploaded) {
      this.setState({ isUploaded: true });
      const canvas = this.dummyCanvas!; //document.createElement('canvas');
      canvas.width = 1200;
      canvas.height = 630;
      this.drawToCanvas(canvas, result);
      const ctx = canvas.getContext("2d");
      if (!ctx) {
        return;
      }

      ctx.save();
      const fontSize = Math.round(canvas.width / 20.0);
      ctx.fillStyle = "rgba(255,255,255,1)";
      ctx.shadowColor = "rgba(0,0,0,1)";
      ctx.shadowOffsetX = 0;
      ctx.shadowOffsetY = 0;
      ctx.shadowBlur = canvas.width / 20;
      ctx.font = `bold ${fontSize}px serif`;
      ctx.textAlign = "center";
      ctx.fillText("診断結果", 160, fontSize + 8);
      ctx.restore();

      if (process.env.NODE_ENV === "production") {
        firebase
          .storage()
          .ref(`/1/${result.token}.png`)
          .putString(canvas.toDataURL("image/png"), "data_url")
          .then(task => {
            console.log(task);
          })
          .catch(err => {
            console.error(err);
          });
      } else {
        console.log("mock upload");
      }
    }
  };
  render() {
    return (
      <div>
        <canvas
          ref={r => (this.dummyCanvas = r)}
          style={{
            transformOrigin: "0 0",
            transform: "scale(0.2, 0.2)",
            position: "absolute",
            display: "none"
          }}
        />
        <ResultCanvas
          ref={r => (this.canvas = r)}
          width={this.state.windowWidth * 2}
          height={this.state.windowWidth * 2}
          style={{
            width: this.state.windowWidth,
            height: this.state.windowWidth
          }}
        />
      </div>
    );
  }
}
