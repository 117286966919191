import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Paragraph, Title } from "../components";

type Props = RouteComponentProps & {};
type State = {};
class NotFound extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div style={{ padding: 64 }}>
        <Title>404</Title>
        <Paragraph>ページがみつかりませんでした</Paragraph>
      </div>
    );
  }
}

export default withRouter(NotFound);
