import React, { Component } from "react";
import { Label, ShareTextArea, SubButton } from ".";
import { ResultModel, shareText } from "../models";

type Props = {
  result: ResultModel;
  onPressCopy: (textArea: HTMLTextAreaElement) => void;
};
type State = {
  text: string;
  isCopied: boolean;
};

export default class CopyResultSection extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.genState(props);
  }
  componentWillReceiveProps(props: Props) {
    this.setState(this.genState(props));
  }
  genState = (props: Props) => ({
    text: shareText(props.result, "copy"),
    isCopied: false
  });
  textArea: HTMLTextAreaElement | null = null;
  onPressButton = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    this.textArea!.readOnly = true;
    this.props.onPressCopy(this.textArea!);
    this.setState({ isCopied: true });
    this.textArea!.readOnly = false;
  };
  render() {
    return (
      <div style={{ padding: "8px", maxWidth: "380px", margin: "0 auto" }}>
        <div style={{ textAlign: "left" }}>
          <Label>コピペ用</Label>
        </div>
        <ShareTextArea
          ref={r => (this.textArea = r)}
          value={this.state.text}
          onChange={() => {}}
          rows={4}
        />
        <div>
          <SubButton onClick={e => this.onPressButton(e)}>
            {this.state.isCopied ? "コピーOK" : "全文コピー"}
          </SubButton>
        </div>
      </div>
    );
  }
}
