import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";

const Colors = {
  background: "#44413f",
  accent: "#F4B699",
  accentDark: "#ef956b"
};

export const Container = styled.div`
  text-align: center;
  margin: 0 auto;
  max-width: 720px;
`;
export const TopBar = styled.div`
  text-align: center;
  background: #000;
  height: 48px;
  width: 100%;
`;
export const Header = styled.header`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;

  width: 100%;
`;
export const Watermark = styled.img`
  display: block;
  width: 120px;
  height: 120px;
  margin: 16px auto;
`;
export const AppIcon = styled.img`
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 100%;
`;
export const DownloadButton = styled.img`
  display: inline-block;
  width: 110px;
  height: 32px;
`;
export const Title = styled.h1`
  font-size: 28px;
  margin: 32px 0 0 0;
`;
export const Description = styled.h2`
  font-size: 12px;
  margin: 0;
`;
export const SubTitle = styled.h2`
  font-size: 18px;
  margin: 0;
  padding: 32px 0;
`;
export const Paragraph = styled.p`
  font-size: 16px;
  margin: 8px;
`;
export const Link = styled(RouterLink)`
  cursor: pointer;
  display: inline-block;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
  background-color: ${Colors.accent};
  padding: 0.4em 1em;
  border-radius: 32px;
  box-sizing: border-box;
  user-select: none;
  &:active {
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.4);
  }
  &:hover {
    background-color: ${Colors.accentDark};
  }

  display: inline-block;
  font-size: 18px;
  padding: 0.4em 3em;
  margin-top: 8px;
`;
export const Button = styled.a`
  cursor: pointer;
  display: inline-block;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
  background-color: ${Colors.accent};
  padding: 0.4em 1em;
  border-radius: 32px;
  box-sizing: border-box;
  user-select: none;
  opacity: 0.95;
  &:active {
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.4);
  }
  &:hover {
    opacity: 1;
  }
`;
export const SubButton = styled(Button)`
  display: block;
  font-size: 12px;
  width: 8em;
  margin: 8px auto;
  color: #000a;
  background-color: #fff;
  &:hover {
    background-color: #eee;
  }
`;
export const StartButton = styled(Button)`
  font-size: 18px;
  padding: 0.4em 3em;
  margin-top: 8px;
`;
export const InputContainer = styled.div`
  width: 100%;
  padding: 16px;
  margin: 32px 0;
  box-sizing: border-box;
`;
export const NameInput = styled.input`
  display: block;
  box-sizing: border-box;
  width: 80%;
  max-width: 480px;
  font-size: 18px;
  color: #000;
  border-radius: 3px;
  padding: 0.4em 0.5em;
  margin: 0 auto;
`;
export const ResultCanvas = styled.canvas``;
export const Label = styled.span`
  font-size: 12px;
  margin: 0;
  padding: 0.5em 1em;
  color: #000a;
  background: #fff;
  border-radius: 3px 3px 0 0;
`;
export const ShareTextArea = styled.textarea`
  display: block;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  border: 0;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.6);
`;
export const BorderBox = styled.div`
  border: 1px solid #fff1;
  border-radius: 3px;
  padding: 16px 0;
  background: #fff3;
  box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.4);
`;
export const ResultContainer = styled.div`
  margin: 32px 0;
`;
const ShareButton = styled(Button)`
  font-size: 18px;
  margin: 4px;
  padding: 0.4em 1.5em;
  display: inline-flex;
  align-items: center;
`;
export const TwitterShareButton = styled(ShareButton)`
  background-color: #55acee;
`;
export const LineShareButton = styled(ShareButton)`
  background-color: #1dcd00;
`;
