import firebase from "firebase/app";
import React, { Component } from "react";
import ReactGA from "react-ga";
import {
  BrowserRouter,
  Redirect,
  Route,
  RouteComponentProps,
  Switch
} from "react-router-dom";
import {
  AppIcon,
  Container,
  Header,
  Paragraph,
  StartButton
} from "./components";
import Generator from "./containers/Generator";
import NotFound from "./containers/NotFound";
import Result from "./containers/Result";

var config = {
  apiKey: "AIzaSyB6TNGe0dV0Y9eMq5jVoko2Lqy0-mSchu4",
  authDomain: "poetrymaker-note15.firebaseapp.com",
  databaseURL: "https://poetrymaker-note15.firebaseio.com",
  projectId: "poetrymaker-note15",
  storageBucket: "gs://poetrymaker-note15.appspot.com/",
  messagingSenderId: "199969796577"
};
firebase.initializeApp(config);

/**
 * 診断メーカー風
 *
 * - / ホーム。 /1/にリダイレクト
 *
 * - /1/ ジェネレータ
 *   - 未生成のとき
 *     - 名前の入力欄
 *     - 生成ボタン
 *   - 生成済みのとき
 *     - IDは1+「ポエム画生成に必要な情報」+名前にする
 *     - ランダムなポエムをcanvasに生成。1+「ポエム画ハッシュ」で写真をアップロードする（こっそり）
 *     - 再作成ボタン
 *     - ツイートボタン、コピーボタン
 *        - /1/result/:token と /1/ をシェアする
 *
 * - /1/result/:token シェアされる画面
 *   - Functionsにリダイレクト
 *     - index.htmlのOGPを置き換えて返す
 *       - 画像はtokenの「ポエム画ハッシュ」から取得
 *       - tokenから名前を取得
 *   - tokenから名前を表示
 *   - tokenからcanvas生成
 *   - 名前の入力欄と生成ボタン
 *     - /1/ にリダイレクトして生成
 *
 */
ReactGA.initialize("UA-128204367-2", {
  debug: process.env.NODE_ENV !== "production",
  testMode: process.env.NODE_ENV !== "production"
});

class Analytics extends React.Component<RouteComponentProps<any>> {
  componentDidMount() {
    this.sendPageChange(
      this.props.location.pathname,
      this.props.location.search
    );
  }

  componentDidUpdate(prevProps: RouteComponentProps<any>) {
    if (
      this.props.location.pathname !== prevProps.location.pathname ||
      this.props.location.search !== prevProps.location.search
    ) {
      this.sendPageChange(
        this.props.location.pathname,
        this.props.location.search
      );
    }
  }

  sendPageChange(pathname: string, search: string = "") {
    const page = pathname + search;
    ReactGA.set({ page });
    ReactGA.pageview(page);
  }

  render() {
    return null;
  }
}

const AnalyticsTracker = () => {
  return <Route component={Analytics} />;
};

const appstoreUrl =
  "http://note15.jp/app?utm_source=web_poetry_maker&utm_medium=1";
class App extends Component {
  onClickAppStoreButton = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    ReactGA.event({ category: "AppStore", action: "Click" });
    window.location.href = appstoreUrl;
  };
  render() {
    return (
      <BrowserRouter>
        <AnalyticsTracker />
        <Container>
          <a
            style={{
              fontFamily: "beautiful",
              opacity: 0,
              position: "absolute",
              left: -9999
            }}
          >
            あ
          </a>
          <Header>
            <Switch>
              <Redirect path="/" exact to="/1/" />
              <Route path="/1/" exact component={Generator} />
              <Route path="/1/result/:token" component={Result} />
              <Route component={NotFound} />
            </Switch>
            <div
              style={{
                textAlign: "center",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 32
              }}
            >
              <a href={appstoreUrl} onClick={this.onClickAppStoreButton}>
                <AppIcon src="/images/icon.png" />
              </a>
              <div
                style={{
                  marginLeft: 16,
                  textAlign: "left"
                }}
              >
                <Paragraph style={{ fontSize: 14 }}>
                  あなたの言葉で、
                  <br />
                  ポエム画がつくれます。
                </Paragraph>
                <StartButton
                  href={appstoreUrl}
                  onClick={this.onClickAppStoreButton}
                  style={{
                    fontSize: 14,
                    marginTop: 0,
                    padding: "0.4em 2em"
                  }}
                >
                  アプリをみてみる
                </StartButton>
              </div>
            </div>
          </Header>
        </Container>
      </BrowserRouter>
    );
  }
}

export default App;
