export const DEFAULT_NAME = "NoName";

export type ResultModel = {
  name: string;
  text: string;
  imageUrl: string;
  token: string;
  data: {
    image: number;
    type: number;
    text: number[];
  };
};

const analyticsParams = (source: string) => {
  if (source == "line") {
    // LINE経由はダイレクトリンクになってしまうため
    return "&utm_source=line&utm_medium=social";
  }
  return "";
};

export const shareUrl = (result: ResultModel, source: string) =>
  `https://shindanmaker.note15.jp/1/result/${
    result.token
  }?name=${encodeURIComponent(result.name)}`;
export const shareText = (result: ResultModel, source: string) => {
  const text = result.text.replace(/\n/g, "");
  const name = result.name;
  const url = shareUrl(result, source) + analyticsParams(source);
  return `【${text}】\nそう思う日が、${name}に訪れるでしょう。\n#あなたの人生を変えるポエム診断\n${url}`;
};
