import md5 from "md5";
import ReactGA from "react-ga";
import { ResultModel } from "../models";
import { POETRY_DATA } from "../models/Poetry";
import { IMAGES } from "../models/Images";

const Images: string[] = [];
for (var i = 1; i <= IMAGES; i++) {
  Images.push(`a${i}`);
}

var patterns = 0;
POETRY_DATA.forEach(type => {
  patterns += type[0].length * type[1].length * type[2].length;
});
export const PATTERNS = patterns;

class PoetryGenerator {
  constructor() {}
  generate = (name: string): ResultModel => {
    ReactGA.event({ category: "Poetry", action: "Generate" });
    const type = Math.floor(Math.random() * POETRY_DATA.length);
    const data = {
      image: Math.floor(Math.random() * Images.length),
      type,
      text: [0, 1, 2].map(i => this.getRandomTextIndex(type, i))
    };
    return {
      name,
      text: this.makeText(data.type, data.text),
      imageUrl: this.makeImageUrl(data.image),
      token: this.makeToken(data),
      data
    };
  };
  from = (token: string, query: string): ResultModel | null => {
    const nameMatches = query.match("name=([^&]+)");
    const name = (nameMatches && decodeURIComponent(nameMatches[1])) || "";
    const matches = token.match(
      "^([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{3})(.{3})$"
    );
    if (!matches || !matches[6]) {
      return null;
    }
    const [_, image, type, text1, text2, text3, _hash] = matches!;
    const data = {
      image: parseInt(image),
      type: parseInt(type),
      text: [text1, text2, text3].map(t => parseInt(t))
    };
    if (token != this.makeToken(data)) {
      console.error("invalid token");
      return null;
    }
    return {
      name,
      text: this.makeText(data.type, data.text),
      imageUrl: this.makeImageUrl(data.image),
      token,
      data
    };
  };
  private getRandomTextIndex = (type: number, part: number) =>
    Math.floor(Math.random() * POETRY_DATA[type][part].length);
  private makeText = (type: number, text: number[]) => {
    const texts = text.map((i, part) => POETRY_DATA[type][part][i]);
    for (const i of [1, 2]) {
      const pos = texts[i].indexOf("<");
      if (pos > 0) {
        const prefix = texts[i].substr(0, pos);
        texts[i - 1] = texts[i - 1] + prefix;
        texts[i] = texts[i].substr(pos + 1);
      }
    }
    return texts.join("\n");
  };
  private makeImageUrl = (image: number) => `/images/${Images[image]}.jpg`;
  private makeToken = (data: {
    image: number;
    type: number;
    text: number[];
  }) => {
    const t = [data.image, data.type, ...data.text]
      .map(s => this.z(s, 3))
      .join("");
    return t + md5("p28YaqeJG" + t).substr(0, 3);
  };
  private z = (i: number, digits: number) => {
    return (Array(digits).join("0") + i).slice(-digits);
  };
}
export default new PoetryGenerator();
